<template>
    <div class="search">
        <div class="head">
            <div class="auto-1440">
                <Breadcrumb type="grey" />
                <Search v-model="params" @search="onSearch" />
            </div>
        </div>
        <div class="search_main">
            <div class="auto-1440">
                <div class="summary">
                    <div class="keyword dot">
                        <span>关键词：</span>
                        <span class="red">{{ keyword }}</span>
                    </div>
                    <div class="result">检索结果：{{ total }}个模型</div>
                </div>
                <div class="list">
                    <a-list :data-source="list" :loading="loading">
                        <a-list-item slot="renderItem" slot-scope="item">
                            <ListItem :data="item" />
                        </a-list-item>
                    </a-list>
                    <Pagination
                        :pageSize="params.pageSize"
                        :total="total"
                        @pageChange="pageChange"
                        :current="params.pageIndex"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '~/components/common/Breadcrumb'
import Search from '~/components/Learning/List/Search'
import ListItem from '~/components/Learning/List/ListItem'
import Pagination from '~/components/common/Pagination'

import { GetList, GetListSenior } from '@/api/model'
export default {
    metaInfo() {
        return {
            title: this.$route.meta.title || '文物出版社',
            meta: [
                {
                    name: 'keyWords',
                    content: this.$route.meta.title || '文物出版社',
                },
                {
                    name: 'description',
                    content: this.$route.meta.title || '文物出版社',
                },
            ],
        }
    },
    components: {
        Breadcrumb,
        Search,
        ListItem,
        Pagination,
    },
    data() {
        return {
            meta: {},
            list: [],
            params: {
                pageIndex: 1,
                pageSize: 5,
                subject: '',
            },
            total: 0,
            loading: false,
            searchkey: ['fullText', 'title', 'tags', 'content'],
        }
    },
    computed: {
        keyword() {
            let str = ''
            this.searchkey.forEach(item => {
                if (this.params[item]) {
                    str = str + this.params[item] + ','
                }
            })
            return str
                .split(',')
                .filter(i => i)
                .join(',')
        },
    },
    methods: {
        onSearch(obj) {
            console.log(obj)
            const { content, fullText, tags, title } = obj
            this.params.content = content
            this.params.fullText = fullText
            this.params.tags = tags
            this.params.title = title
        },
        async getList() {
            this.loading = true
            await GetListSenior(this.params)
                .then(res => {
                    const { data, total } = res
                    if (this.keyword) {
                        const keys = this.keyword.split(',')
                        data.forEach(element => {
                            element.Name = element.Name || ''
                            element.Info = element.Info || ''
                            keys.forEach(item => {
                                element.Name = element.Name.replace(
                                    new RegExp(item, 'g'),
                                    "<span style='color:red;'>" +
                                        item +
                                        '</span>'
                                )
                                element.Info = element.Info.replace(
                                    new RegExp(item, 'g'),
                                    "<span style='color:red;'>" +
                                        item +
                                        '</span>'
                                )
                            })
                        })
                    }
                    this.list = data
                    this.total = total
                })
                .catch(e => e)
            this.loading = false
        },
        // 翻页
        pageChange(page) {
            console.log(page)
            this.params.pageIndex = page.page
            document.documentElement.scrollTop = 0
            this.getList()
        },
        // 翻页
        onSearch(obj) {
            console.log(obj)
            const { content, fullText, tags, title } = obj
            this.params.content = content
            this.params.fullText = fullText
            this.params.tags = tags
            this.params.title = title
            this.params.pageIndex = 1
            this.getList()
        },
    },
    created() {
        this.params.fullText = this.$route.params.key
        this.getList()
    },
}
</script>
<style lang="less" scoped>
.head {
    background-color: #eeeeee;
    padding-bottom: 5px;
    /deep/.breadcrumb {
        border-bottom: 0;
        margin-bottom: 5px;
    }
    /deep/.ant-input {
        background-color: #fff;
    }
}
.summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 80px;
    font-weight: 600;
    font-size: 20px;
    color: rgb(51, 51, 51);
    .keyword {
        flex: 1;
        .red {
            color: red;
        }
    }
    .result {
        flex-shrink: 0;
    }
}
.list {
    padding-bottom: 90px;
    /deep/.ant-list-item {
        border-bottom: 0 !important;
        margin-bottom: 20px;
    }
    /deep/.item {
        flex: 1;
        h2:hover {
            span {
                color: #f6a75c !important;
            }
        }
    }
    /deep/.pagenation {
        margin-top: 50px;
    }
}
</style>
